import React from "react"

import styles from "./postLinkBlock.module.scss"

const PostLinkBlock = ({ link, title }) => {
  let psl = require("psl")

  const extractHostName = url => {
    let hostName

    url.indexOf("//") > -1
      ? (hostName = url.split("/")[2])
      : (hostName = url.split("/")[0])

    // Find & remove port number
    hostName = hostName.split(":")[0]

    // Find & remove "?"
    hostName = hostName.split("?")[0]

    return hostName
  }

  const shortLink = psl.get(extractHostName(link))
  console.log(shortLink)

  return (
    <section className="wrapper copy-width">
      <a
        className={styles.block}
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.website}>{shortLink}</p>
      </a>
    </section>
  )
}

export default PostLinkBlock
