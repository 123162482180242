import React from "react"

import styles from "./postContent.module.scss"

const PostContent = ({ post }) => (
  <section className="wrapper copy-width">
    <article
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: post.html }}
    ></article>
  </section>
)

export default PostContent
