import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import cx from "classnames"
import styles from "./postHero.module.scss"

const PostHero = ({ post }) => {
  const query = useStaticQuery(graphql`
    query {
      file(name: { eq: "footer" }) {
        childMarkdownRemark {
          frontmatter {
            linkedin
          }
        }
      }
    }
  `)

  const linkedin = query.file.childMarkdownRemark.frontmatter.linkedin

  return (
    <section className="wrapper copy-width">
      <Img
        className={styles.image}
        fluid={post.frontmatter.featured_image.childImageSharp.fluid}
      />
      <h1 className={styles.title}>{post.frontmatter.title}</h1>
      <div className={cx("flex justify-between", styles.info)}>
        <p>Published on {post.frontmatter.date}</p>
        <p>
          Find me on{" "}
          <a href={linkedin} rel="noopener noreferrer" target="_blank">
            Linkedin
          </a>
        </p>
      </div>
    </section>
  )
}

export default PostHero
