import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PostLogo from "../components/postLogo"
import PostHero from "../components/postHero"
import PostContent from "../components/postContent"
import PostLinkBlock from "../components/postLinkBlock"
import FooterPostList from "../components/footerPostList"
import FooterSection from "../components/footerSection"

const PostTemplate = props => {
  const post = props.data.markdownRemark

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <PostLogo />
      <PostHero post={post} />
      <PostContent post={post} />
      {post.frontmatter.external_link !== "" && (
        <PostLinkBlock
          link={post.frontmatter.external_link}
          title={post.frontmatter.external_link_title}
        />
      )}
      <FooterPostList currentPost={post.id} />
      <FooterSection />
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        featured_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        external_link
        external_link_title
      }
      html
    }
  }
`
